import consumer from "./consumer"

consumer.subscriptions.create("SignalChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("connected!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("disconnected!");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var username = localStorage.getItem('username');
    if (username == data.username) {
      $("#invoice_id").attr('value', data.invoice_id);
      $("#esign_form").submit();
    }
  }
});
